import React from 'react'
import { Box, Input } from 'theme-ui'
import Icon from '@solid-ui-components/ContentIcon'
import { useTranslation } from 'react-i18next'

const FormInput = ({ placeholder, icon, ...props }) => {
  const {t} = useTranslation()

  return (
    <Box variant='forms.field'>
      <Icon content={icon} size='xs' round ml='3' pl='2' />
      <Input
        {...props}
        type={props.type.toLowerCase()}
        placeholder={t(placeholder?.text)}
      />
    </Box>
  )
}
export default FormInput
