import React from 'react'
import { Box, Textarea } from 'theme-ui'
import Icon from '@solid-ui-components/ContentIcon'
import { useTranslation } from 'react-i18next'

const FormTextarea = ({ placeholder, icon, ...props }) => {
  const {t} = useTranslation()

  return (
    <Box variant='forms.field'>
      <Icon content={icon} size='xs' round ml='3' pl='2' mr='1' />
      <Textarea {...props} type='textarea' placeholder={t(placeholder?.text)} />
    </Box>
  )
}

export default FormTextarea
